<style lang="less" scoped>
#customerlist {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .model-action {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(68, 125, 245, 1);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>
<template>
  <div class="customerlist" id="customerlist">
    <div class="tab-top">
      <table-title />
      <table-action alias="custmer"  @search="onSearch" @success="onSuccess" />
    </div>
    <a-table
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :pagination="{
        current, 
        total,
        pageSize: size, 
      }"
      @change="tabChange"
      style="margin-top: 13px"
    >
      <div slot-scope="record" slot="action">
        <span class="model-action" @click="onEdit(record.customer_id)">修改</span>
        <a-divider v-show="record.customer_status==2" type="vertical" />
        <span v-show="record.customer_status==2" class="model-action" @click="onRestore(record.customer_id)">恢复</span>
        <a-divider v-show="record.customer_status==1" type="vertical" />
        <span v-show="record.customer_status==1" class="model-action" @click="comfirmDelete(record.customer_id)">删除</span>
        <!-- <table-delete @comfirm="comfirmDelete(record.customer_id)"></table-delete> -->
      </div>
    </a-table>
  </div>
</template>

<script>
import { fetchCustomerlist, updateCustomer } from "@/api/customer";
const columns = [
  {
    title: "客户ID",
    dataIndex: "customer_id",
  },
  {
    title: "客户名称",
    dataIndex: "customer_name",
  },
  {
    title: "客户简称",
    dataIndex: "abbreviated_name",
  },
  {
    title: "客户全称",
    dataIndex: "full_name",
  },
  {
    title: "客户状态",
    dataIndex: "customer_status_text",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  }
];

export default {
  name: "Customerlist",
  components: {
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
    "table-action": resolve =>
      require(["@/components/table/TableAction.vue"], resolve),
    "table-delete": resolve =>
      require(["@/components/table/TableDelete.vue"], resolve)
  },
  data() {
    return {
      data: [],
      columns,
      total: 10,
      size: 10,
      current: 1,
      search: undefined,
      loading: true
    };
  },
  created() {
    this.getCustomerlist();
  },
  methods: {
    async getCustomerlist() {
      const that = this;
      this.loading = true;
      try {
        let res = await fetchCustomerlist({
          page_size: this.size,
          page: this.current,
          search_value: this.search,
        });
        if (!res) return;
        this.loading = false;
        this.total = res.total;
        this.data = res.customer_list.map((item, index) => {
          switch (item.customer_status) {
            case 1:
              item.customer_status_text = "有效";
              break;
            case 2:
              item.customer_status_text = "无效";
              break;
            default:
              break;
          }
          if (item.abbreviated_name.length <= 0) {
            item.abbreviated_name = "-";
          }
          item["key"] = item.customer_id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.getCustomerlist();
    },
    onSearch(e) {
      this.search = e;
      this.current = 1;
      this.getCustomerlist();
    },
    onSuccess(e) {
      this.$router.push({ name: "customerDetail" });
    },
    onEdit(customerId) {
      this.$router.push({ name: "customerDetail", query: { customerId: customerId }});
    },
    comfirmDelete(customerId) {
      this.updateStatus(customerId, 2);
    },
    onRestore(customerId) {
      this.updateStatus(customerId, 1);
    },
    async updateStatus(customerId, status) {
      this.loading = true;
      try {
        await updateCustomer({
          customer_id: customerId,
          customer_status: status
        });
        this.loading = false;
        if (status == 1) {
          this.$message.success("客户已恢复");  
        }else{
          this.$message.success("客户已删除");  
        }
        this.getCustomerlist();
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    }
  }
};
</script>
